import { FetchError } from "behavioral/retry-error";
import { getLogger } from "logging/logger";
import { wait } from "utils/async";

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export class HttpService {
  protected readonly baseEndpoint;
  private readonly authToken;
  private readonly maxRetries;
  private readonly backoffFactor;

  /**
   * The base version of an HTTP service with build in Bearer auth
   * handling and retries.
   *
   * @param baseEndpoint The base URL to which relative paths in requests will be appended
   * @param authToken The value of the Bearer token to use in requests
   * @param maxRetries The max number of retries that will be attempted
   * @param backoffFactor The time in millis by which to scale back each attempt. This value will be doubled for each successive attempt. E.g. 150, 300, 600ms.
   */
  public constructor(
    baseEndpoint: string,
    authToken: string,
    maxRetries = 3,
    backoffFactor = 150
  ) {
    this.baseEndpoint = baseEndpoint;
    this.authToken = authToken;
    this.maxRetries = maxRetries;
    this.backoffFactor = backoffFactor;
  }

  private getRequestOptions(
    method: Method,
    withKeepAlive = false
  ): RequestInit {
    const headers: HeadersInit = {
      Authorization: `Bearer ${this.authToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return {
      method: method.toString(),
      headers,
      keepalive: withKeepAlive,
      mode: "cors",
    };
  }

  /**
   * Performs the actual fetch request and return the promise
   * with the value requested. If the request fails with a
   * `!response.ok` and a status that isn't 4XX, it will retry
   * up to the number of `retryAttempts`.
   */
  private async wrapFetch<T>(
    method: Method,
    path: string,
    body?: unknown,
    withKeepAlive = false,
    attempt = 0
  ): Promise<T> {
    const options = this.getRequestOptions(method, withKeepAlive);

    if (body) {
      options.body = JSON.stringify(body);
    }

    const fullPath = this.baseEndpoint + path;
    const response = await fetch(fullPath, options);

    // Return now if it was a positive response
    if (response.ok) {
      // Get the JSON body and cast it to the requested type
      return response.json().then((data) => data as T);
    }

    // If retries are exhausted, throw an error
    if (
      attempt >= this.maxRetries ||
      (response.status >= 400 && response.status < 500)
    ) {
      const msg =
        response.status > 0
          ? `Received status code ${response.status}`
          : `Error sending fetch request`;
      throw new FetchError(`Fetch request failed. Caused by: ${msg}`, response);
    }

    // If retries are not exhuated, resend the request
    const delay = this.backoffFactor * Math.pow(2, attempt);
    getLogger().info(
      `Fetch failed for path '${path}'. Attempt: ${
        attempt + 1
      }. Retrying with delay: ${delay}ms`
    );

    // wait for the delay
    await wait(delay);
    return this.wrapFetch(method, path, body, withKeepAlive, ++attempt);
  }

  /**
   * Performs a GET request to the requested relative path.
   */
  protected async get<T>(path: string): Promise<T> {
    return this.wrapFetch(Method.GET, path);
  }

  /**
   * Performs a POST request to the requested relative path.
   */
  protected async post<T>(
    path: string,
    body?: unknown,
    withKeepAlive = false
  ): Promise<T> {
    return this.wrapFetch(Method.POST, path, body, withKeepAlive);
  }

  /**
   * Performs a PUT request to the requested relative path.
   */
  protected async put<T>(
    path: string,
    body?: unknown,
    withKeepAlive = false
  ): Promise<T> {
    return this.wrapFetch(Method.PUT, path, body, withKeepAlive);
  }
}
