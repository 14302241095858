import { SocureError } from "errors/socure-error";
import { SensorPermission } from "../sensor-permissions";

export enum PermissionState {
  Denied = "denied",
  Error = "error",
  Unknonwn = "unknown",
}

export class PermissionError extends SocureError {
  constructor(
    private _permission: SensorPermission,
    private _permissionState: PermissionState,
    msg?: string,
    cause?: Error
  ) {
    super(msg || `'${_permission}' permission ${_permissionState}`, cause);
  }

  get permission(): SensorPermission {
    return this._permission;
  }

  get state(): PermissionState {
    return this._permissionState;
  }
}
