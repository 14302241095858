export class Utils {
  /**
   * Returns the current monotonic timestamp for
   * the browser.
   */
  public static getRelativeTimestamp(): number {
    return window.performance.now();
  }

  /**
   * Takes a given timestamp and truncates it to an int. If the value passed
   * is null or undefined, it will return the current
   * monotonic timestamp.
   */
  public static processTimestamp(val?: number | null): number {
    const timeToUse = val || this.getRelativeTimestamp();

    return Math.trunc(timeToUse);
  }
}
