import { DeviceLocationRequest } from "./device-location-request";

export interface WebAttributes {
  userAgent?: string;
  browser?: string;
  browserVersion?: string;

  pixelDepth?: number;
  hardwareConcurrency?: number;
  availableScreenWidth?: number;
  availableScreenHeight?: number;
  maxTouchPoints?: number;
  connectionDownlink?: number;
  connectionEffectiveType?: string;
  connectionRtt?: number;
  connectionSaveData?: boolean;

  webRtcIpLan?: string;
  webRtcIpPublic?: string;
  colorDepth?: number;
  webDriver?: boolean;
  webGlUnmaskedVendor?: string;
  webGlUnmaskedRenderer?: string;
}

export enum BatteryState {
  UNKNOWN = "unknown",
  CHARGING = "charging",
  UNPLUGGED = "unplugged",
  FULL = "full",
}

export interface Battery {
  batteryState?: BatteryState;
  batteryLevel?: number;
}

export enum ApplicationType {
  UNKNOWN = "unknown",
  NATIVE = "native",
}

export interface DeviceIngestionRequest {
  sdkVersion: string;
  platform: string;
  os?: string;
  osVersion?: string;
  deviceModel?: string;
  deviceManufacturer?: string;
  deviceType?: string;
  deviceMemory?: number;
  viewportWidth: number;
  viewportHeight: number;
  screenWidth: number;
  screenHeight: number;
  devicePixelRatio: number;
  timeZone?: string;
  timeZoneOffset?: number;
  language?: string;

  webAttributes: WebAttributes;

  location?: DeviceLocationRequest;
  battery?: Battery;
  applicationType: ApplicationType;
}
