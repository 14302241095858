import { SessionDataRequest } from "./models/session-data-request";
import { HttpService } from "http/http-service";
import { getLogger } from "logging/logger";
import { WindowState } from "utils/window-state";

/** The ingestion API for sending session data to the server. */
export class BehavioralIngestionService extends HttpService {
  public constructor(baseEndpoint: string, sdkKey: string) {
    super(baseEndpoint + "/api/v1", sdkKey);
    getLogger().debug(
      `Behavioral Ingestion Service configured with baseUrl: ${baseEndpoint}`
    );
  }

  /** Uploads session data request to the server. */
  async uploadSessionData(request: SessionDataRequest): Promise<void> {
    return this.post("/session-data", request, WindowState.isHidden());
  }
}
