import { getLogger } from "logging/logger";
import { Resolvable, withTimeout } from "utils/async";

export interface WebRtcInfo {
  webRtcIpLan?: string;
  webRtcIpPublic?: string;
}

interface IceInfo {
  address?: string;
  type: string;
}

export class WebRtcSensor {
  private static readonly ICE_SERVER = "stun:stun.l.google.com:19302";

  public static async getWebRtcInfo(): Promise<WebRtcInfo> {
    const waitForFinish = new Resolvable<boolean, void>();
    const rtcInfo: WebRtcInfo = {};

    // Get the RTC Connection from any of the
    // browser specific options if the default isn't available
    const rtcConn =
      window.RTCPeerConnection ||
      ((window as any).mozRTCPeerConnection as unknown as RTCConfiguration) ||
      ((window as any).webkitRTCPeerConnection as unknown as RTCConfiguration);

    // If there is no WebRTC client, return an
    // empty object
    if (!rtcConn) {
      return Promise.resolve({} as WebRtcInfo);
    }

    let pc: RTCPeerConnection | undefined;
    try {
      pc = new rtcConn({
        iceServers: [{ urls: WebRtcSensor.ICE_SERVER }],
      });

      if ("createDataChannel" in pc === false) {
        return Promise.resolve({} as WebRtcInfo);
      }

      pc.createDataChannel("");
      pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          getLogger().debug("No more ice candidates");
          waitForFinish.resolve(true);
          return;
        }

        const iceInfo = this.extractIpAddress(ice.candidate);

        if (iceInfo.type === "host") {
          rtcInfo.webRtcIpLan = iceInfo.address;
        } else {
          rtcInfo.webRtcIpPublic = iceInfo.address;
        }
      };

      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
    } catch {
      // ignore and return default
    }

    const result = await withTimeout(waitForFinish.promise, 3000, false);
    getLogger().debug(
      `webRTC connection ${result ? "succeeded" : "timed out"}`
    );

    if (pc) {
      pc.close();
    }

    return rtcInfo;
  }

  private static extractIpAddress(iceCandidate: RTCIceCandidate): IceInfo {
    const iceInfo: IceInfo = {
      type: "",
    };
    const splitIce = iceCandidate.candidate.split(" ");

    if (iceInfo) {
      iceInfo.address = iceCandidate.address || undefined;
      iceInfo.type = iceCandidate.type || "";

      if (splitIce.length >= 7) {
        if (!iceInfo.address) {
          iceInfo.address = splitIce[4];
        }

        if (!iceInfo.type) {
          iceInfo.type = splitIce[7];
        }
      }
    }

    return iceInfo;
  }
}
