import { DeviceIngestionService } from "device/device-ingestion-service";
import { ClientConfig, Config, TokenFormat } from "config";
import { Logger, getLogger } from "logging/logger";
import { SigmaSessionManager } from "sigma-session-manager";
import { Resolvable, withTimeout } from "utils/async";
import { SessionInfo } from "device/session-info";

export class SigmaDeviceManager {
  private static instance: SigmaDeviceManager;
  private sigmaSessionManager: SigmaSessionManager | undefined;
  private configPromise: Promise<Config>;
  private readonly deviceIngestionService: DeviceIngestionService;
  private readonly sessionInfoResolvable = new Resolvable<
    SessionInfo,
    string
  >();

  private constructor(clientConfig: ClientConfig) {
    let deviceUrl = process.env.DEVICE_URL; // default to value in process.env
    Logger.initialize(process.env.DEBUG === "true");

    // Replace the sdk subdomain with ingestion if this is being
    // service by our standard URLs
    if (
      clientConfig.scriptSrc.hostname.match(/sdk(.stage)?.dv.socure.(io|us)/)
    ) {
      const baseHost = clientConfig.scriptSrc.host.substring("sdk.".length);
      deviceUrl = clientConfig.scriptSrc.protocol + "//ingestion." + baseHost;
      getLogger().debug("deviceUrl replaced", deviceUrl);
    }
    this.deviceIngestionService = new DeviceIngestionService(
      deviceUrl,
      clientConfig.publicKey
    );

    this.configPromise = this.deviceIngestionService.getServerConfig();
    this.autoStartWhenReady(clientConfig, this.configPromise);
  }

  // ============= Class Methods ===================
  /**
   * Automatically starts the SigmaDevice session when the
   * config has been pulled and the DOM is ready or timedout.
   */
  private autoStartWhenReady(
    clientConfig: ClientConfig,
    configPromise: Promise<Config>
  ) {
    // Wait for config to load and document to be ready
    // to start the session
    Promise.all([configPromise, this.watchForDocumentReady()])
      .then(([serverConfig]) => {
        // Combine the serverConfig with any options allowed
        // to be set by the client config to allow the clientConfig
        // to override the server setting
        const config = { ...serverConfig, ...clientConfig };

        if (!config.tokenFormat) {
          config.tokenFormat = TokenFormat.jwt;
        }

        getLogger().debug("Combined Config", config);
        this.sigmaSessionManager = new SigmaSessionManager(
          config,
          this.sessionInfoResolvable,
          this.deviceIngestionService
        );
        this.sigmaSessionManager.start();
        this.autoStopOnUnload();
      })
      .catch((e) => {
        getLogger().error("Couldn't start process due to error", e);
      });
  }
  /**
   * Waits for the device to be ready. If it takes longer than 200ms,
   * it will resolve anyway so that the rest of the SDK can carry on
   */
  private watchForDocumentReady(): Promise<boolean | void> {
    const docReadyPromise = new Promise<boolean | void>((resolve) => {
      // If the state is not "loading" the DOMContentLoaded event has not fired yet.
      // Source: https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
      // NOTE: In the docs, it says that "interactive" means the event is about to
      // fire. In testing, we've found that by the time we read the value "interactive",
      // the DOMContentLoaded event may have already fired.
      if (document.readyState == "loading") {
        getLogger().debug(
          "DOMContentLoaded has not fired - adding listener on DOMContentLoaded",
          document.readyState
        );
        window.addEventListener("DOMContentLoaded", () => {
          getLogger().debug("DOMContentLoaded callback running");
          resolve();
        });
      } else {
        getLogger().debug(
          "DOMContentLoaded already fired",
          document.readyState
        );
        resolve();
      }
    });

    // Resolves when the doc is ready or it's waited 200ms. Whichever
    // comes first
    return withTimeout<boolean | void>(docReadyPromise, 200, true);
  }

  /**
   * Automatically stops the app when the DOM is unloaded
   */
  private autoStopOnUnload() {
    window.addEventListener("unload", () => {
      this.sigmaSessionManager?.stop();
    });
  }

  // ============= Static Methods ===================

  /**
   * Initializes the Socure Device SDK based on the configuration
   * passed
   */
  public static initialize(clientConfig: ClientConfig) {
    // Don't initialize a second instance if one already exists
    if (this.instance) {
      getLogger().warn("SigmaDeviceManager already created");
      return;
    }

    this.instance = new SigmaDeviceManager(clientConfig);
  }

  /**
   * Returns the token used to fetch data for
   * the session recorded.
   */
  public static async getSessionId(): Promise<string> {
    if (!this.instance) {
      return Promise.reject("SDK not initialized");
    }

    const sessionInfo = await this.instance.sessionInfoResolvable.promise;
    return sessionInfo.sessionToken;
  }
}
