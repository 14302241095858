import { BaseEvent } from "./base-event";

/**
 * The types of events available when
 * Focus changes between elements.
 */
export enum FocusChangeEventType {
  UNKNOWN = "UNKNOWN",
  FOCUS = "FOCUS",
  BLUR = "BLUR",
}

/**
 * Represents a user changing focus between elements
 * with the webpage.
 */
export interface FocusChangeEvent extends BaseEvent {
  /**
   * The types of events available when
   * Focus changes between elements.
   */
  type: FocusChangeEventType;
}
