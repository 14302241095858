/**
 * The possible token formats available for the
 * legacy fingerprinting flow.
 */
export enum TokenFormat {
  jwt = "jwt",
  base64 = "base64",
}

/**
 * The configuration for Behavioral Data Capture
 */
export interface BehavioralConfig {
  /**
   * The URL of the behavioral ingestion service
   */
  host: string;

  /**
   * The list of available flags for the behavioral service
   */
  flags: {
    /**
     * Specifies whether the behavioral service is enabled. If
     * enabled, behavioral data capture will be run
     */
    enabled: boolean;
  };

  /**
   * Specifies the duration, in milliseconds, passed to the Moonsense SDK
   * for the session time limit
   */
  sessionDuration: number;

  /**
   * Specifies the bundle generation interval, in milliseconds, passed to
   * the Moonsense SDK
   */
  bundleGenerationInterval: number;

  /**
   * Specifies a ratio between 0 and 1 that dictates the
   * the percentage of times behavioral data should be
   * captured. For example, given a value of `0.5`, there
   * is a 50% chance that a given instance will start
   * behavioral data capture.
   */
  sampleRate: number;
}

/**
 * The configation for Device Fingerprinting
 */
export interface DeviceConfig {
  /**
   * The URL of the device ingestion service
   */
  host: string;

  /**
   * The list of available flags for the behavioral service
   */
  flags: {
    /**
     * Specifies whether the device V1 fingerprinting is enabled.
     * If not enabled, V2 will be used.
     */
    enableV1: boolean;

    /**
     * If enabled, the full precision of the location can be captured.
     * If disabled, location precision should be limited.
     */
    enableFullPrecisionLocation: boolean;
  };
}

/**
 * The full configuration used by the
 * SigmaSessionManager to control application
 * flow
 */
export interface Config {
  /**
   * The public key assigned through ID+
   */
  publicKey: string;

  /**
   * The format of token to be returned as the sessionId.
   * This is only valid for when device 2.0 is disabled.
   */
  tokenFormat: TokenFormat;

  /**
   * The configuration for Behavioral Data Capture
   */
  behavioral: BehavioralConfig;

  /**
   * The configation for Device Fingerprinting
   */
  device: DeviceConfig;
}

/**
 * The configuration parameters that the client can specify for the app.
 * */
export type ClientConfig = Partial<Config> &
  Pick<Config, "publicKey" | "tokenFormat"> & {
    scriptSrc: URL;
  };
