import { Config } from "config";
import { HttpService } from "http/http-service";
import { SessionCreateRequest } from "./session-create-request";
import { SessionCreateResponse } from "./session-create-response";
import { DeviceIngestionRequest } from "./device-ingestion-request";
import { DeviceLocationRequest } from "./device-location-request";

/**
 * Handles the requests to the DeviceIngestionAPI
 */
export class DeviceIngestionService extends HttpService {
  public constructor(baseEndpoint: string, sdkKey: string) {
    super(baseEndpoint + "/api/v1", sdkKey);
  }

  /**
   * Retrieves the SDK configuration to use from the
   * backend
   */
  public async getServerConfig(): Promise<Config> {
    return this.get(`/configuration?sdkVersion=${process.env.VERSION}`);
  }

  /**
   * Sends the request to create a new session. If
   * a deviceId or sessionId are passed in the request,
   * the backend will verify and return them or generate
   * replacements.
   */
  public async createSession(
    request: SessionCreateRequest
  ): Promise<SessionCreateResponse> {
    return this.post("/session", request);
  }

  /**
   * Sends the device fingerprint data to the backend
   */
  public async createDevice(request: DeviceIngestionRequest): Promise<void> {
    return this.post("/device", request);
  }

  /**
   * Sends the device fingerprint data to the backend
   */
  public async updateDeviceLocation(
    request: DeviceLocationRequest
  ): Promise<void> {
    return this.put("/device/location", request);
  }
}
