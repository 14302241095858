export interface WebGlInfo {
  webGlUnmaskedVendor?: string;
  webGlUnmaskedRenderer?: string;
}

export class WebGlSensor {
  public static getWebGlInfo(): WebGlInfo {
    const canvas = document.createElement("canvas");
    let glTest: WebGLRenderingContext | null = null;
    try {
      glTest =
        canvas.getContext("webgl") ||
        (canvas.getContext("experimental-webgl") as WebGLRenderingContext);
    } catch (e) {
      // ignore
    }

    // Return empty if webGL context couldn't be created
    if (!glTest) {
      return {};
    }

    const gl: RenderingContext = glTest;
    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    if (debugInfo) {
      const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) as string;
      const renderer = gl.getParameter(
        debugInfo.UNMASKED_RENDERER_WEBGL
      ) as string;

      return {
        webGlUnmaskedVendor: vendor,
        webGlUnmaskedRenderer: renderer,
      };
    }

    return {};
  }
}
