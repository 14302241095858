import {
  KeyPressEvent,
  KeyPressEventType,
} from "behavioral/sensor-models/key-press-event";
import { BaseElementSensor } from "./base-element-sensor";
import { SensorType } from "./sensor-type";
import { Utils } from "utils/utils";

export class KeyPressSensor extends BaseElementSensor<KeyPressEvent> {
  private static readonly ELEMENT_EVENTS: (keyof WindowEventMap)[] = [
    "keyup",
    "keydown",
  ];

  constructor() {
    super(KeyPressSensor.ELEMENT_EVENTS);
  }

  public override getSensorType(): SensorType {
    return SensorType.KEY_PRESS;
  }

  protected override processEvent(event: KeyboardEvent): KeyPressEvent {
    let type = KeyPressEventType.UNKNOWN;
    switch (event.type?.toLowerCase()) {
      case "keydown":
        type = KeyPressEventType.KEY_DOWN;
        break;
      case "keyup":
        type = KeyPressEventType.KEY_UP;
        break;
    }

    return {
      clientTime: Utils.processTimestamp(event.timeStamp),
      type,
    };
  }
}
