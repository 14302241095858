import { BaseEvent } from "./sensor-models/base-event";
import { SensorFieldMap, SensorType } from "./sensors/sensor-type";
import { Events } from "./api/models/events";
import { SessionDataRequest } from "./api/models/session-data-request";
import { getLogger } from "logging/logger";
export class SensorCollector {
  // All event data is optional to allow
  // for minimal network request size
  private events: Events = {};
  private sessionDataIndex = 0;

  addEvent(s: SensorType, e: BaseEvent) {
    const field = SensorFieldMap.get(s);
    if (!field) {
      getLogger().error("unrecognized sensor type passed", s);
      return;
    }

    this.appendEvent(field, e);
  }

  private appendEvent<
    K extends keyof Events,
    E extends NonNullable<Events[K]>[0] // Type of Events for given key
  >(k: K, event: E) {
    // Create the field if needed
    if (!(k in this.events)) {
      this.events[k] = [];
    }

    (this.events[k] as NonNullable<E[]>).push(event);
  }

  flush(): SessionDataRequest | undefined {
    const tmp = this.events;
    this.events = {};

    const hasEvents = Object.keys(tmp).length > 0;

    // Return early if there are no events
    if (!hasEvents) {
      return undefined;
    }

    this.sessionDataIndex++;

    const request: SessionDataRequest = {
      sessionDataIndex: this.sessionDataIndex,
      sessionData: {
        events: tmp,
      },
    };

    return request;
  }
}
