import { Resolvable } from "utils/async";
import { Config } from "config";
import { Behavior } from "behavioral/behavior";
import { getLogger } from "logging/logger";
import { SessionInfo } from "device/session-info";
import { DeviceIngestionService } from "device/device-ingestion-service";
import { SessionCreateRequest } from "device/session-create-request";
import { DeviceInfoCapture } from "device/device-info-capture";
import { SessionCreateResponse } from "device/session-create-response";
import { GeolocationSensor } from "device/sensors/geolocation-sensor";
import { DeviceLocationRequest } from "device/device-location-request";

/**
 * Manages the state of the underlying device
 * and behavioral data collection
 */
export class SigmaSessionManager {
  private readonly DEVICE_V1_STORAGE_KEY = "devicer_id";
  private readonly DEVICE_STORAGE_KEY = "_s_did";
  private readonly SESSION_STORAGE_KEY = "_s_sid";

  private readonly config;
  private readonly behavioralModule;
  private readonly deviceIngestionPublicKey: DeviceIngestionService;
  private readonly sessionInfoResolvable: Resolvable<SessionInfo, string>;
  private readonly sessionDeviceIngestion = new Resolvable<
    DeviceIngestionService,
    string
  >();

  private capturedPosition?: DeviceLocationRequest;
  private deviceLocationRecorded = false;
  private deviceFingerprintResolvable = new Resolvable<void, unknown>();

  public constructor(
    config: Config,
    sessionInfoResolvable: Resolvable<SessionInfo, string>,
    deviceIngestionPublicKey: DeviceIngestionService
  ) {
    this.config = config;
    this.sessionInfoResolvable = sessionInfoResolvable;
    this.deviceIngestionPublicKey = deviceIngestionPublicKey;

    this.behavioralModule = new Behavior(
      config,
      this.sessionInfoResolvable.promise
    );
  }

  /** Starts the device risk app. */
  start(): void {
    const chance = Math.random();
    getLogger().info("Behavioral capture chance: ", chance);
    if (
      this.config.behavioral.flags.enabled &&
      chance <= this.config.behavioral.sampleRate
    ) {
      getLogger().info("starting behavioral");
      this.behavioralModule.start();
    }

    // Start the location lookup
    this.getPosition()
      .then(() => {
        // Call the location setter and let it
        // determine if it needs to send the
        // location or not
        this.setGeolocation().catch(() => {
          // noop. fallback to user request
        });
      })
      .catch(() => {
        // noop. error captured already.
      });

    // Create a session
    this.createSession()
      .then((info) => {
        getLogger().debug("Session Created", info);
        this.sessionInfoResolvable.resolve(info);

        // Create the session version of the deviceIngestionService
        const deviceIngestionSession = new DeviceIngestionService(
          this.config.device.host,
          info.sessionToken
        );
        this.sessionDeviceIngestion.resolve(deviceIngestionSession);
      })
      .catch((e) => {
        getLogger().warn(
          "failed to get sessionId when starting behavioral module",
          e
        );
        this.sessionInfoResolvable.reject("Could not resolve session id");
        this.sessionDeviceIngestion.reject("Could not resolve session id");
      });

    // Start the device v2 fingerprinter
    this.device2Fingerprint()
      .then(() => {
        this.deviceFingerprintResolvable.resolve();
      })
      .catch((e) => {
        getLogger().warn("Could not record device info", e);
        this.deviceFingerprintResolvable.reject();
      });
  }

  private async createSession(): Promise<SessionInfo> {
    const request = this.getExistingSessionId();
    const result = await this.deviceIngestionPublicKey.createSession(request);

    this.storeSessionInfo(result);

    return {
      deviceToken: result.deviceId,
      sessionToken: result.sessionToken,
    };
  }

  /**
   * Fetches the existing session info from the local storage
   * for reuse.
   */
  private getExistingSessionId(): SessionCreateRequest {
    const request: SessionCreateRequest = {};

    // Lookup device v2 id if it exists
    try {
      const existingDevice = localStorage.getItem(this.DEVICE_STORAGE_KEY);
      if (existingDevice) {
        request.deviceId = existingDevice;
        getLogger().debug("using existing device id");
      }
    } catch (e) {
      getLogger().warn("error getting existing device");
    }

    // If v2 not available, check for v1
    if (!request.deviceId) {
      try {
        const existingDeviceJsonStr = localStorage.getItem(
          this.DEVICE_V1_STORAGE_KEY
        );
        if (existingDeviceJsonStr) {
          localStorage.removeItem(this.DEVICE_V1_STORAGE_KEY);

          const legacyDevice = JSON.parse(existingDeviceJsonStr) as {
            value: string;
          };
          request.deviceId = String(legacyDevice.value);

          getLogger().debug("using existing device id");
        }
      } catch (e) {
        getLogger().warn("error getting existing device");
      }
    }

    try {
      const existingSession = sessionStorage.getItem(this.SESSION_STORAGE_KEY);
      if (existingSession) {
        request.sessionToken = existingSession;
        getLogger().debug("using existing session id");
      }
    } catch (e) {
      getLogger().warn("error getting existing session");
    }

    return request;
  }

  /**
   * Stores the session info in local storage for reuse on
   * subsequent attempts
   */
  private storeSessionInfo(sessionInfo: SessionCreateResponse) {
    try {
      localStorage.setItem(this.DEVICE_STORAGE_KEY, sessionInfo.deviceId);
    } catch (e) {
      getLogger().warn("error storing device info");
    }

    try {
      sessionStorage.setItem(
        this.SESSION_STORAGE_KEY,
        sessionInfo.sessionToken
      );
    } catch (e) {
      getLogger().warn("error storing session info");
    }
  }

  private async device2Fingerprint(): Promise<void> {
    // Capture the device info
    const deviceCapturePromise = DeviceInfoCapture.capture();

    // Wait for the device info and the session details
    const [deviceIngestionSession, deviceIngestionRequest] = await Promise.all([
      this.sessionDeviceIngestion.promise,
      deviceCapturePromise,
    ]);

    // Add in the location if we have it
    if (this.capturedPosition) {
      deviceIngestionRequest.location = this.capturedPosition;
      this.deviceLocationRecorded = true;
    }

    await deviceIngestionSession.createDevice(deviceIngestionRequest);
  }

  /** Stops the device risk app. */
  stop(): void {
    this.behavioralModule.stop();
  }

  /**
   * A simple wrapper around geolocation lookup to allow capture
   * to be included during startup or in response to an
   * {@link addGeolocation} request but reuse the value if it has
   * already been requested.
   */
  private async getPosition(): Promise<DeviceLocationRequest> {
    if (!this.capturedPosition) {
      this.capturedPosition = await GeolocationSensor.getLocation(
        this.config.device.flags.enableFullPrecisionLocation
      );
      getLogger().debug("position determined", this.capturedPosition);
    }

    return this.capturedPosition;
  }

  /** triggers geolocation to be added */
  async setGeolocation(): Promise<void> {
    // Short circuit if the position was included in
    // the device creation request
    if (this.deviceLocationRecorded) {
      getLogger().info("device location already recorded");
      return;
    }

    try {
      // Wait for the device ingestion to finish
      await this.deviceFingerprintResolvable.promise;

      // Get the position
      const position = await this.getPosition();

      try {
        const deviceService = await this.sessionDeviceIngestion.promise;
        await deviceService.updateDeviceLocation(position);
        this.deviceLocationRecorded = true;
      } catch (e) {
        getLogger().warn("there was an error reporting the position", e);
      }
    } catch (e) {
      getLogger().warn("there was an error determining the position", e);
    }

    return;
  }
}
