/**
 * Specifies the level of logging that should be
 * used by the Logger.
 *
 * @internal
 */
export enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  OFF,
}
