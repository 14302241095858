import { ClickEvent } from "behavioral/sensor-models/click-event";
import { BaseElementSensor } from "./base-element-sensor";
import { SensorType } from "./sensor-type";
import { Utils } from "utils/utils";

export class ClickSensor extends BaseElementSensor<ClickEvent> {
  private static readonly ELEMENT_EVENTS: (keyof WindowEventMap)[] = ["click"];

  constructor() {
    super(ClickSensor.ELEMENT_EVENTS);
  }

  public override getSensorType(): SensorType {
    return SensorType.CLICK;
  }

  protected override processEvent(event: PointerEvent): ClickEvent {
    return {
      clientTime: Utils.processTimestamp(event.timeStamp),
      offset: {
        x: event.clientX,
        y: event.clientY,
      },
    };
  }
}
