import { Utils } from "utils/utils";
import { BaseElementSensor } from "./base-element-sensor";
import { SensorType } from "./sensor-type";
import {
  InputChangeEvent,
  InputChangeEventAction,
} from "behavioral/sensor-models/input-change-event";

export class InputChangeSensor extends BaseElementSensor<InputChangeEvent> {
  private static readonly ELEMENT_EVENTS: (keyof WindowEventMap)[] = ["input"];

  constructor() {
    super(InputChangeSensor.ELEMENT_EVENTS);
  }

  public override getSensorType(): SensorType {
    return SensorType.INPUT_CHANGE;
  }

  protected override processEvent(
    event: InputEvent
  ): InputChangeEvent | undefined {
    if (event.target instanceof HTMLInputElement) {
      const reportedAction = (event.inputType || "unknown").toLowerCase();
      let action = InputChangeEventAction.UNKNOWN;

      switch (reportedAction) {
        case "deletebycut":
          action = InputChangeEventAction.CUT;
          break;
        case "insertfrompaste":
          action = InputChangeEventAction.PASTE;
          break;
      }

      return {
        clientTime: Utils.processTimestamp(event.timeStamp),
        action,
      };
    }

    return undefined;
  }
}
