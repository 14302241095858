export interface UserPreferenceInfo {
  timeZone?: string;
  timeZoneOffset?: number;
  language?: string;
}

export class UserPreferenceSensor {
  public static getUserPreferenceInfo(): UserPreferenceInfo {
    let timeZone: string | undefined = undefined;

    if (window.Intl && window.Intl.DateTimeFormat) {
      timeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return {
      timeZone: timeZone,
      timeZoneOffset: -1 * new Date().getTimezoneOffset(),
      language: navigator?.language,
    };
  }
}
