import { LogLevel } from "./log-level";

/**
 * Provides a common internal method of
 */
export class Logger {
  private static instance: Logger;

  private logLevel: LogLevel;

  /**
   * Sets up the logger instance
   */
  public static initialize(isDebug = false) {
    if (!this.instance) {
      let level = LogLevel.ERROR;

      if (isDebug) {
        level = LogLevel.DEBUG;
      }
      this.instance = new Logger(level);
    }
  }

  /**
   * Returns an instance of the logger.
   */
  public static getLogger(): Logger {
    if (!this.instance) {
      console.error("logger called before initialization - using default");
      this.initialize();
    }

    return this.instance;
  }

  private constructor(logLevel = LogLevel.ERROR) {
    this.logLevel = logLevel;
  }

  /**
   * A helper method to simplify outputting logs to the console
   * when they may or may not have an object included
   */
  private outputLog(
    log: (...data: unknown[]) => void,
    msg: string,
    obj?: unknown
  ) {
    if (obj) {
      log(msg, obj);
    } else {
      log(msg);
    }
  }

  /**
   * Prints a DEBUG level log to the console if DEBUG level logs are turned on
   */
  public debug(msg: string, obj?: unknown) {
    if (LogLevel.DEBUG >= this.logLevel) {
      this.outputLog(console.debug, msg, obj);
    }
  }

  /**
   * Prints a INFO level log to the console if INFO level logs are turned on
   */
  public info(msg: string, obj?: unknown) {
    if (LogLevel.INFO >= this.logLevel) {
      this.outputLog(console.info, msg, obj);
    }
  }

  /**
   * Prints a WARN level log to the console if WARN level logs are turned on
   */
  public warn(msg: string, obj?: unknown) {
    if (LogLevel.WARN >= this.logLevel) {
      this.outputLog(console.warn, msg, obj);
    }
  }

  /**
   * Prints a ERROR level log to the console if ERROR level logs are turned on
   */
  public error(msg: string, obj?: unknown) {
    if (LogLevel.ERROR >= this.logLevel) {
      this.outputLog(console.error, msg, obj);
    }
  }
}

export const getLogger = (): Logger => {
  return Logger.getLogger();
};
