/**
 * This file sets up the initialization of the SDK so that it automatically runs
 * when the script tag is included in a webpage.
 *
 * It also exposes the public methods to the outside world.
 */

import { ClientConfig, TokenFormat } from "config";
import { SigmaDeviceManager } from "sigma-device-manager";

// Pull the config from the script tag
const script = document.querySelector(
  `script[src*=${process.env.ARTIFACT_NAME}]`
);
if (!script) {
  throw new Error("Cannot initialize SDK");
}

/** Reads the client config from the provided script tag. */
function parseClientConfig(element: HTMLScriptElement): ClientConfig {
  if (!element.dataset.publicKey) {
    throw new Error("data-public-key is a required parameter");
  }

  const publicKey = element.dataset.publicKey;
  if (publicKey === "") {
    throw new Error("data-public-key cannot be empty");
  }

  const tokenFormat =
    element.dataset?.tokenFormat?.toLowerCase() as TokenFormat;

  const scriptSrc = new URL(element.src);

  return {
    publicKey,
    tokenFormat,
    scriptSrc,
  };
}

// Initialize the SDK
const clientConfig = parseClientConfig(<HTMLScriptElement>script);
SigmaDeviceManager.initialize(clientConfig);

/**
 * Returns the device session that can be used to associate this session with a
 * transaction.
 *
 * @deprecated use `getSessionToken()` instead
 */
export function getDeviceSessionId(): Promise<string> {
  return SigmaDeviceManager.getSessionId();
}

/**
 * Returns the device session that can be used to associate this session with a
 * transaction.
 */
export function getSessionToken(): Promise<string> {
  return SigmaDeviceManager.getSessionId();
}

/**
 * Provides a method for setting the value of geolocation
 * for use in device fingerprinting.
 *
 * @deprecated
 */
export const setGeolocation = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  position: GeolocationPosition
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) => {};
