import { BaseEvent } from "behavioral/sensor-models/base-event";
import { getLogger } from "logging/logger";
import { SensorType } from "./sensor-type";

/**
 * The base level of a sensor. This contains the core
 * logic needed by all sensors.
 */
export abstract class BaseSensor<SocureEvent extends BaseEvent> {
  private eventListeners: Array<
    (sensorType: SensorType, event: BaseEvent) => void
  > = [];

  /**
   * A convenience method just to allow
   * the SensorType to be used for messaging,
   * logging, and to avoid Typescript
   * shenanigans.
   */
  public abstract getSensorType(): SensorType;

  /**
   * Turns on the sensor. When the sensor
   * is on/running, it will return data
   * for collection to be sent to the
   * backend. Sensors should be stopped
   * if data collection is not to be
   * running.
   */
  public abstract start(): void;

  /**
   * Turns off the sensor. Data
   * will not be collected or emitted.
   */
  public abstract stop(): void;

  /**
   * Adds an event listener to the sensor to subscribe
   * to new events being created.
   */
  public addEventListener(
    listener: (sensorType: SensorType, event: SocureEvent) => void
  ) {
    this.eventListeners.push(
      // cast because typescript doesn't seem to think SocureEvent is
      // compatible with BaseEvent
      listener as (sensorType: SensorType, event: BaseEvent) => void
    );
  }

  /**
   * Should be called by any sensor when there is data
   * to be emitted to the event listeners.
   */
  protected emit(event: SocureEvent): void {
    getLogger().debug(`${this.getSensorType()} Event`, event);

    this.eventListeners.forEach((c) => {
      try {
        c(this.getSensorType(), event);
      } catch (e) {
        getLogger().warn("Error processing event listener callback", e);
      }
    });
  }
}
