import { Config } from "config";
import { SessionInfo } from "device/session-info";
import { getLogger } from "logging/logger";
import { BaseSensor } from "./sensors/base-sensor";
import { FocusChangeSensor } from "./sensors/focus-change-sensor";
import { SensorCollector } from "./sensor-collector";
import { BaseEvent } from "./sensor-models/base-event";
import { Resolvable } from "utils/async";
import { BehavioralIngestionService } from "./api/behavioral-ingestion-service";
import { InputChangeSensor } from "./sensors/input-change-sensor";
import { ClickSensor } from "./sensors/click-sensor";
import { FormSubmitSensor } from "./sensors/form-submit-sensor";
import { KeyPressSensor } from "./sensors/key-press-sensor";
import { Utils } from "utils/utils";

/**
 * The current state of the behavior module
 */
export enum BehaviorState {
  RUNNING,
  PAUSED,
  STOPPED,
}

/** The main entry point for behavioral code. */
export class Behavior {
  private readonly config;
  private behaviorApiService = new Resolvable<
    BehavioralIngestionService,
    Error
  >();
  private state = BehaviorState.STOPPED;
  private sensors: Array<BaseSensor<BaseEvent>> = [];
  private sensorCollector = new SensorCollector();
  private flushTimer?: NodeJS.Timer;
  private sessionTimeout?: NodeJS.Timeout;
  private sessionStartTime?: number;
  private visibilityListener?: (e: Event) => void;

  constructor(config: Config, sessionInfoPromise: Promise<SessionInfo>) {
    this.config = config;

    sessionInfoPromise
      .then((sessionInfo) => {
        const ingestionApi = new BehavioralIngestionService(
          config.behavioral.host,
          sessionInfo.sessionToken
        );
        this.behaviorApiService.resolve(ingestionApi);
      })
      .catch(() => {
        getLogger().error("session token failed. aborting");
        this.behaviorApiService.reject(new Error("couldn't determine session"));
        this.stop(); // stop all capture
      });
  }

  /**
   * Kicks off all behavioral collection. This includes
   * starting all configured sensors and flushing
   * captured data to the backend.
   */
  start(): void {
    // Don't allow a start call if it's already running
    if (
      this.state === BehaviorState.PAUSED ||
      this.state === BehaviorState.RUNNING
    ) {
      return;
    }

    this.sessionStartTime = Utils.getRelativeTimestamp();

    // Add the configured sensors
    this.configureSensors();

    // Start up all the processes to collect data
    this.resumeCollection();

    // Start the page visibility listener
    this.startVisibilityMonitor();
  }

  /**
   * Starts all data collection processes on
   * configured sensors
   */
  private resumeCollection(): void {
    // Only start things up if they aren't currently
    // running
    if (
      this.state === BehaviorState.STOPPED ||
      this.state === BehaviorState.PAUSED
    ) {
      this.state = BehaviorState.RUNNING;

      // Start the sensors and timers
      this.startFlushCycle();
      this.startSensors();
    }
  }

  /**
   * Provides a soft stop that pauses data collection
   * but doesn't completely stop the session. This
   * is mainly to pause for backgrounding.
   */
  private stopCollection(): void {
    getLogger().debug("stopping sensors");
    this.state = BehaviorState.PAUSED;
    this.stopSensors();

    // Stop the flush cycle
    if (this.flushTimer) {
      getLogger().debug("flush timer stopped");
      clearInterval(this.flushTimer);
      this.flushTimer = undefined;
    }

    // Stop the timeout
    if (this.sessionTimeout) {
      getLogger().debug("session timeout stopped");
      clearTimeout(this.sessionTimeout);
      this.sessionTimeout = undefined;
    }

    // Run another flush if sensors were started
    if (this.sensors.length > 0) {
      this.flush();
    }
  }

  /**
   * Fully stops the session and all ongoing
   * collection
   */
  stop(): void {
    getLogger().info("stopping session");
    this.stopCollection();
    this.stopVisibilityMonitor();

    // Clear the sensors
    this.sensors = [];
    this.state = BehaviorState.STOPPED;
  }

  /**
   * Sets up the document visibilitystate listener
   * to handle background and foregrounding.
   */
  private startVisibilityMonitor(): void {
    this.visibilityListener = () => {
      this.processVisibilityState();
    };

    document.addEventListener("visibilitychange", this.visibilityListener);

    // Run an initial check to stop everything if the page is
    // already backgrounded
    this.processVisibilityState();
  }

  /**
   * Performs the actions when the visibility state changes
   */
  private processVisibilityState(): void {
    if (document.visibilityState === "hidden") {
      getLogger().info("Page has been backgrounded");
      this.stopCollection();
    } else if (document.visibilityState === "visible") {
      // Check if the session duration has lapsed
      const currentDuration =
        Utils.getRelativeTimestamp() - (this.sessionStartTime || 0);
      if (currentDuration >= this.config.behavioral.sessionDuration) {
        getLogger().info("session expired while backgrounded");
        this.stop();
        return;
      }

      // If still within the session duration, resume the session
      getLogger().info("Page has been foregrounded");
      this.resumeCollection();
    }
  }

  /**
   * Removes the document visibility state listener
   */
  private stopVisibilityMonitor(): void {
    if (this.visibilityListener) {
      document.removeEventListener("visibilitychange", this.visibilityListener);
      this.visibilityListener = undefined;
    }
  }

  /**
   * Sets up the sensors the module is configured
   * to use
   */
  private configureSensors() {
    // Add the configured sensors
    this.sensors.push(new ClickSensor());
    this.sensors.push(new FocusChangeSensor());
    this.sensors.push(new FormSubmitSensor());
    this.sensors.push(new KeyPressSensor());
    this.sensors.push(new InputChangeSensor());
  }

  /**
   * Turns on sensor collection for all tracked sensors
   */
  private startSensors(): void {
    this.sensors.forEach((s) => {
      s.addEventListener((s, e) => this.sensorCollector.addEvent(s, e));
      s.start();
    });
  }

  /**
   * Turns off sensor collection for all tracked sensors
   */
  private stopSensors(): void {
    this.sensors.forEach((s) => {
      s.stop();
    });
  }

  /**
   * Sets up the interval to flush sensor data to the backend
   * and sets up the session duration timeout
   */
  private startFlushCycle(): void {
    // Set the session Timeout
    const curTime = Utils.getRelativeTimestamp();
    const remaining =
      (this.sessionStartTime || 0) +
      this.config.behavioral.sessionDuration -
      curTime;
    this.sessionTimeout = setTimeout(() => {
      getLogger().info("session duration reached");
      this.stop();
    }, remaining);

    // Set a timeout to run flush every `bundleGenerationInterval`
    this.flushTimer = setInterval(() => {
      this.flush();
    }, this.config.behavioral.bundleGenerationInterval);
  }

  /**
   * Flushes all collected sensor data to the backend
   */
  private flush(): void {
    const events = this.sensorCollector.flush();

    if (events) {
      events.metadata = {
        osVersion: "",
        socureSdkVersion: process.env.VERSION,
      };

      getLogger().info(
        `bundle flushed. index: ${events.sessionDataIndex}`,
        events
      );

      this.behaviorApiService.promise
        .then((service) => {
          service.uploadSessionData(events).catch((e) => {
            getLogger().warn(
              `could not flush bundle ${events.sessionDataIndex}`
            );

            // Stop the session is the auth is no longer valid
            if (e?.response?.status === 401) {
              getLogger().error("stopping session due to invalid auth");
              this.stop();
            }
          });
        })
        .catch(() => {
          getLogger().warn(
            "data not flushed due to lack of service configuration"
          );
        });
    }
  }
}
