import { Utils } from "utils/utils";
import { BaseElementSensor } from "./base-element-sensor";
import {
  FocusChangeEvent,
  FocusChangeEventType,
} from "behavioral/sensor-models/focus-change-event";
import { SensorType } from "./sensor-type";

export class FocusChangeSensor extends BaseElementSensor<FocusChangeEvent> {
  private static readonly ELEMENT_EVENTS: (keyof WindowEventMap)[] = [
    "focus",
    "blur",
  ];

  constructor() {
    super(FocusChangeSensor.ELEMENT_EVENTS);
  }

  public override getSensorType(): SensorType {
    return SensorType.FOCUS_CHANGE;
  }

  protected override processEvent(
    event: FocusEvent
  ): FocusChangeEvent | undefined {
    if (event.target instanceof HTMLInputElement) {
      const reportedType = (event.type || "unknown").toLowerCase();
      let eventType = FocusChangeEventType.UNKNOWN;

      switch (reportedType) {
        case "focus":
          eventType = FocusChangeEventType.FOCUS;
          break;
        case "blur":
          eventType = FocusChangeEventType.BLUR;
          break;
      }

      return {
        clientTime: Utils.processTimestamp(event.timeStamp),
        type: eventType,
      };
    }

    return undefined;
  }
}
