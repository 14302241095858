import { Events } from "behavioral/api/models/events";

/**
 * The list of available sensors
 */
export enum SensorType {
  CLICK = "Click",
  FOCUS_CHANGE = "FocusChange",
  FORM_SUBMIT = "FormSubmit",
  INPUT_CHANGE = "InputChange",
  KEY_PRESS = "KeyPress",
  LIFE_CYCLE = "LifeCycle",
}

/**
 * A mapping of SensorType to their respective key's in
 * the bundle to be passed to the backend
 */
export const SensorFieldMap = new Map<SensorType, keyof Events>([
  [SensorType.CLICK, "clickEvents"],
  [SensorType.FOCUS_CHANGE, "focusChangeEvents"],
  [SensorType.FORM_SUBMIT, "formSubmitEvents"],
  [SensorType.KEY_PRESS, "keyPressEvents"],
  [SensorType.INPUT_CHANGE, "inputChangeEvents"],
]);
