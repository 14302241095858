import { DeviceLocationRequest } from "device/device-location-request";
import { getLogger } from "logging/logger";
import { Resolvable } from "utils/async";
import { PermissionError, PermissionState } from "./errors/permission-error";
import { SensorPermission } from "./sensor-permissions";
import { SocureError } from "errors/socure-error";
import { MathUtils } from "utils/math-utils";

export class GeolocationSensor {
  /**
   * The minimum value that is allowed to be captured without
   * the `config.enableFullPrecision` flag being enabled.
   */
  private static readonly BEST_HORIZONTAL_ACCURACY_ALLOWED = 1000;

  public static getLocation(
    enableFullPrecision: boolean
  ): Promise<DeviceLocationRequest> {
    const resolvable = new Resolvable<DeviceLocationRequest, Error>();
    if (navigator.permissions) {
      // See if we have permission in a way that won't ask the user
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((status) => {
          // If so, record it. Otherwise throw an error
          if (status.state === "granted") {
            this.getPosition(enableFullPrecision, resolvable);
          } else {
            // Position is not currently allowed, but
            // we can watch to see if it gets allowed at some point.

            const listener = () => {
              if (status.state === "granted") {
                this.getPosition(enableFullPrecision, resolvable);
                status.removeEventListener("change", listener);
              }
            };
            status.addEventListener("change", listener);
          }
        })
        .catch((e) => {
          getLogger().warn("error checking geolocation permission status", e);
          resolvable.reject(
            new PermissionError(
              SensorPermission.Geolocation,
              PermissionState.Error
            )
          );
        });
    } else {
      getLogger().warn("navigator does not contain a permissions object");
      resolvable.reject(
        new PermissionError(
          SensorPermission.Geolocation,
          PermissionState.Error,
          "navigator permission not available for geolocation"
        )
      );
    }
    return resolvable.promise;
  }

  private static getPosition(
    enableFullPrecision: boolean,
    resolvable: Resolvable<DeviceLocationRequest, Error>
  ): void {
    getLogger().debug("geolocation permission granted");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const response: DeviceLocationRequest = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          horizontalAccuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          verticalAccuracy: position.coords.altitudeAccuracy,
          bearing: position.coords.heading,
          speed: position.coords.speed,
        };

        if (!enableFullPrecision) {
          getLogger().debug("location precision is being reduced");
          this.reducePrecision(response);
        }

        resolvable.resolve(response);
      },
      (error) => {
        getLogger().warn("error getting current position", error);
        resolvable.reject(
          new SocureError(`error getting current position: ${error.message}`)
        );
      },
      {
        enableHighAccuracy: enableFullPrecision,
      }
    );
  }

  private static reducePrecision(position: DeviceLocationRequest) {
    // Return automatically if the accuracy is already worse than the min allowed.
    if (
      position.horizontalAccuracy >=
      GeolocationSensor.BEST_HORIZONTAL_ACCURACY_ALLOWED
    ) {
      return;
    }

    position.latitude = MathUtils.roundToPlaces(position.latitude, 2);
    position.longitude = MathUtils.roundToPlaces(position.longitude, 2);
    position.horizontalAccuracy = 1100;
  }
}
