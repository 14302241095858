import { getLogger } from "logging/logger";
import {
  ApplicationType,
  DeviceIngestionRequest,
  WebAttributes,
} from "./device-ingestion-request";
import { ScreenSensor } from "./sensors/screen-sensor";
import { UserAgentSensor } from "./sensors/user-agent-sensor";
import { UserPreferenceSensor } from "./sensors/user-preference-sensor";
import { WebRtcSensor } from "./sensors/web-rtc-sensor";
import { WebGlSensor } from "./sensors/webgl-sensor";
import { BatterySensor } from "./sensors/battery-sensor";
import { HardwareSensor } from "./sensors/hardware-sensor";
import { ConnectionSensor } from "./sensors/connection-sensor";

/**
 * Performs the capture of the required device information
 * used in the DeviceIngestionService
 */
export class DeviceInfoCapture {
  /**
   * Performs the capture of the required device information
   * used in the DeviceIngestionService
   */
  public static async capture(): Promise<DeviceIngestionRequest> {
    // Async Sensors
    const [userAgentRes, webRtcRes, batteryRes] = await Promise.allSettled([
      UserAgentSensor.getUserAgentInfo(),
      WebRtcSensor.getWebRtcInfo(),
      BatterySensor.getBatteryInfo(),
    ]);

    // Fetch the sensors
    const screenInfo = ScreenSensor.getScreenInfo();
    const userPrefs = UserPreferenceSensor.getUserPreferenceInfo();
    const webGlInfo = WebGlSensor.getWebGlInfo();
    const hardwareInfo = HardwareSensor.getHardwareInfo();
    const connectionInfo = ConnectionSensor.getConnectionInfo();

    const userAgentInfo =
      userAgentRes.status === "fulfilled" ? userAgentRes.value : undefined;

    const webRtcInfo =
      webRtcRes.status === "fulfilled" ? webRtcRes.value : undefined;

    const batteryInfo =
      batteryRes.status === "fulfilled" ? batteryRes.value : undefined;

    // Build the response
    const web: WebAttributes = {
      // UserAgent
      userAgent: userAgentInfo?.userAgent,
      browser: userAgentInfo?.browser,
      browserVersion: userAgentInfo?.browserVersion,

      // Screen
      colorDepth: screenInfo.colorDepth,
      pixelDepth: screenInfo.pixelDepth,
      availableScreenWidth: screenInfo.availableScreenWidth,
      availableScreenHeight: screenInfo.availableScreenHeight,

      // Hardware
      hardwareConcurrency: hardwareInfo.hardwareConcurrency,
      maxTouchPoints: hardwareInfo.maxTouchPoints,

      // Connection
      connectionDownlink: connectionInfo?.downlink,
      connectionEffectiveType: connectionInfo?.effectiveType,
      connectionRtt: connectionInfo?.rtt,
      connectionSaveData: connectionInfo?.saveData,

      // WebDriver
      webDriver: navigator.webdriver || false,

      // WebGL
      webGlUnmaskedVendor: webGlInfo.webGlUnmaskedVendor,
      webGlUnmaskedRenderer: webGlInfo.webGlUnmaskedRenderer,

      // WebRTC
      webRtcIpLan: webRtcInfo?.webRtcIpLan,
      webRtcIpPublic: webRtcInfo?.webRtcIpPublic,
    } as unknown as WebAttributes;

    const request: DeviceIngestionRequest = {
      sdkVersion: process.env.VERSION,
      platform: "WEB",
      applicationType: ApplicationType.NATIVE,

      // UserAgentInfo
      os: userAgentInfo?.os,
      osVersion: userAgentInfo?.osVersion,

      // Device Attributes
      deviceMemory: hardwareInfo?.deviceMemory,

      // Screen Attributes
      viewportWidth: screenInfo.viewportWidth,
      viewportHeight: screenInfo.viewportHeight,
      screenWidth: screenInfo.screenWidth,
      screenHeight: screenInfo.screenHeight,
      devicePixelRatio: screenInfo.devicePixelRatio,

      // Locale
      timeZone: userPrefs.timeZone,
      timeZoneOffset: userPrefs.timeZoneOffset,
      language: userPrefs.language,

      // Web attributes
      webAttributes: web,

      // Battery
      battery: {
        batteryLevel: batteryInfo?.batteryLevel,
        batteryState: batteryInfo?.batteryState,
      },
    };

    getLogger().debug("deviceCapture value", request);
    return request;
  }
}
