export interface HardwareInfo {
  deviceMemory?: number;
  hardwareConcurrency?: number;
  maxTouchPoints?: number;
}

export class HardwareSensor {
  public static getHardwareInfo(): HardwareInfo {
    return {
      deviceMemory: (navigator as { deviceMemory?: number }).deviceMemory,
      hardwareConcurrency: navigator.hardwareConcurrency,
      maxTouchPoints: navigator.maxTouchPoints,
    };
  }
}
