/**
 * A class that wraps an underlying promise which can be resolved or rejected as
 * part of an async operation.
 */
export class Resolvable<T, V> {
  readonly promise;
  private declare resolveFn: (result: T) => void;
  private declare rejectFn: (reason?: V) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolveFn = resolve;
      this.rejectFn = reject;
    });
  }

  /** Resolves the underlying promise. */
  resolve(result: T): void {
    this.resolveFn(result);
  }

  /** Rejects the underlying promise. */
  reject(reason?: V): void {
    this.rejectFn(reason);
  }
}

/**
 * A convenience method for creating a promise which has already been resolved.
 */
export function fulfilled<T>(result: T): Promise<T> {
  return new Promise<T>((resolve) => {
    resolve(result);
  });
}

/**
 * Similar to a `sleep()` function in other languages. Allows
 * for setting up a Promise.race
 *
 * @param timeMillis the time in milliseconds to wait
 */
export const wait = (timeMillis: number): Promise<void> => {
  return new Promise((res) => setTimeout(res, timeMillis));
};

/**
 * Wraps a promise in a timeout so that to force a
 * rejections if it does not return in the specified
 * amount of time.
 *
 * The return promise returns based on the following:
 *   * `prom` succeeds before timeout: the returned promise resolves to the value of prom
 *   * `prom` times out with no `defaultValOnTimeout` set: the returned promise rejects with an error
 *   * `prom` times out with a `defaultValOnTimeout` set: the returned promise resolves to the value of `defaultValOnTimeout`
 *
 * @param prom The promise being timed
 * @param timeMillis the amount of time before it times out
 * @param defaultValOnTimeout if this value is set, instead of rejecting, the promise will resolve to this value
 */
export async function withTimeout<T>(
  prom: Promise<T>,
  timeMillis: number,
  defaultValOnTimeout?: T
): Promise<T> {
  const errorVal = Symbol();
  let timer: NodeJS.Timeout;

  // Create a timeout promise that rejects when it
  // hits the timeout
  const timeoutPromise = new Promise<T>((res, rej) => {
    timer = setTimeout(() => {
      if (defaultValOnTimeout !== undefined) {
        res(defaultValOnTimeout);
      }
      rej(errorVal);
    }, timeMillis);
  });

  try {
    // Try to fetch the result. If the timeout is hit,
    // it should trigger an exception
    const result = await Promise.race([prom, timeoutPromise]).finally(() => {
      clearTimeout(timer);
    });
    return result;
  } catch (e) {
    // If the exception was thrown by the timeout, report
    // it as a timeout. Otherwise return the error
    // from the passed in promise
    if (e === errorVal) {
      throw new Error("request timed out");
    }

    throw e;
  }
}
