export class MathUtils {
  /**
   * Takes a given number and rounds it to the number of specified
   * decimal places.
   */
  public static roundToPlaces(val: number, places: number): number {
    const modifier = Math.pow(10, places);
    return Math.round(val * modifier) / modifier;
  }
}
