import { FormSubmitEvent } from "behavioral/sensor-models/form-submit-event";
import { BaseElementSensor } from "./base-element-sensor";
import { SensorType } from "./sensor-type";
import { Utils } from "utils/utils";

/**
 * Tracks when the user clicks to submit a form.
 */
export class FormSubmitSensor extends BaseElementSensor<FormSubmitEvent> {
  private static readonly ELEMENT_EVENTS: (keyof WindowEventMap)[] = ["submit"];

  constructor() {
    super(FormSubmitSensor.ELEMENT_EVENTS, false);
  }

  public override getSensorType(): SensorType {
    return SensorType.FORM_SUBMIT;
  }

  protected override processEvent(
    event: SubmitEvent
  ): FormSubmitEvent | undefined {
    return {
      clientTime: Utils.processTimestamp(event.timeStamp),
    };
  }
}
