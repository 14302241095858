import { getLogger } from "logging/logger";
import { BaseSensor } from "./base-sensor";
import { BaseEvent } from "behavioral/sensor-models/base-event";

/**
 * Provides the base tracking needed for sensors based
 * on watching DOM elements and their interactions.
 */
export abstract class BaseElementSensor<
  SocureEvent extends BaseEvent
> extends BaseSensor<SocureEvent> {
  // TODO: Add ElementId tracking
  /**
   * The method that will convert the DOM Event into the correct
   * data type to be sent to the backend.
   *
   * @param event The DOM event that occurred
   *
   * @returns The event converted event if it can be converted. `undefined`
   * if the event could not be converted or was filtered out.
   */
  protected abstract processEvent(event: Event): SocureEvent | undefined;

  /** The target.nodeNames to track on HTMLElements */
  private readonly TARGETS_TO_TRACK = ["BUTTON", "INPUT", "SELECT", "TEXTAREA"];

  /**
   * Holds the callback so it can be used when subscribing and
   * unsubscribing from events
   */
  private eventCallback: EventListener;

  constructor(
    /**
     * A list of window events that should be monitored
     */
    protected eventsToTrack: (keyof WindowEventMap)[],

    /**
     * Limits tracking to only form elements listed in
     * {@link BaseElementSensor.TARGETS_TO_TRACK}
     */
    protected targetFormElements = true
  ) {
    super();

    // Setup the callback to be called when an event
    // is triggered. This wraps the actual method
    // to make sure `this` functions properly
    this.eventCallback = (event: Event) => {
      this.eventCaptured(event);
    };
  }

  /**
   * Triggered by the event callback when one of the
   * listeners fires an event. Starts the processing
   * of the event and emits the result.
   */
  private eventCaptured(event: Event): void {
    try {
      // Only track specific elements specified
      // in `TARGETS_TO_TRACK` if `targetFormElements` is true
      if (
        event.target instanceof HTMLElement &&
        (!this.targetFormElements ||
          this.TARGETS_TO_TRACK.includes(event.target.nodeName.toUpperCase()))
      ) {
        const result = this.processEvent(event);

        // Only process if the result is defined
        if (result) {
          this.emit(result);
        }
      }
    } catch (e) {
      getLogger().warn(
        `Error processing event for ${this.getSensorType()} sensor`,
        e
      );
    }
  }

  public override start(): void {
    getLogger().debug(`${this.getSensorType()} Started`);
    this.eventsToTrack.forEach((v) => {
      window.addEventListener(v, this.eventCallback, true);
    });
  }

  public override stop(): void {
    getLogger().debug(`${this.getSensorType()} Stopped`);
    this.eventsToTrack.forEach((v) => {
      window.removeEventListener(v, this.eventCallback, true);
    });
  }
}
