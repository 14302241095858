export interface ScreenInfo {
  viewportWidth: number;
  viewportHeight: number;
  screenWidth: number;
  screenHeight: number;
  devicePixelRatio: number;
  colorDepth: number;
  pixelDepth: number;
  availableScreenWidth: number;
  availableScreenHeight: number;
}

export class ScreenSensor {
  public static getScreenInfo(): ScreenInfo {
    return {
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      devicePixelRatio: window.devicePixelRatio,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth,
      availableScreenWidth: window.screen.availWidth,
      availableScreenHeight: window.screen.availHeight,
    };
  }
}
