import { getLogger } from "logging/logger";
import { version } from "os";

export interface UserAgentInfo {
  userAgent: string;
  deviceArchitecture?: string;
  os?: string;
  osVersion?: string;
  browser?: string;
  browserVersion?: string;
}

interface ExtractedVersion {
  browser?: string;
  version?: string;
}

export class UserAgentSensor {
  public static async getUserAgentInfo(): Promise<UserAgentInfo> {
    const userAgentInfo: UserAgentInfo = {
      userAgent: navigator.userAgent,
    };

    try {
      const values = await (
        navigator as NavigatorUA
      )?.userAgentData?.getHighEntropyValues([
        "architecture",
        "model",
        "platform",
        "platformVersion",
        "fullVersionList",
      ]);

      getLogger().debug("userAgentData fetched", values);

      userAgentInfo.deviceArchitecture = values?.architecture;
      userAgentInfo.os = values?.platform;
      userAgentInfo.osVersion = values?.platformVersion;

      // Determine the Browser/Version
      const extracted = UserAgentSensor.extractBrowserVersion(values);
      if (extracted) {
        userAgentInfo.browser = extracted.browser;
        userAgentInfo.browserVersion = extracted.version;
      }
    } catch (e) {
      getLogger().debug("error performing userAgentData fetch", e);
    }

    return userAgentInfo;
  }

  private static extractBrowserVersion(
    ua?: UADataValues
  ): ExtractedVersion | undefined {
    let extracted: ExtractedVersion | undefined = undefined;

    if (ua) {
      extracted = UserAgentSensor.extractFromList(ua.fullVersionList);

      if (!extracted) {
        extracted = UserAgentSensor.extractFromList(ua.brands);
      }
    }

    return extracted;
  }

  private static extractFromList(
    versionList?: NavigatorUABrandVersion[]
  ): ExtractedVersion | undefined {
    let extracted: ExtractedVersion | undefined = undefined;

    // Short circuit if version list is undefined or empty
    if (!versionList || versionList.length === 0) {
      return;
    }

    // Filter out standard chrome based values if there
    // is more than one item in the list
    let useVersionList = versionList;
    if (useVersionList.length > 1) {
      useVersionList = useVersionList.filter(
        (v) =>
          !v.brand?.trim().toLowerCase().startsWith("not") &&
          v.brand?.trim().toLowerCase() !== "chromium"
      );
    }

    // Combine anything left into a single value.
    // Ideally there will only be one value in the list
    // left at this point.
    extracted = {
      browser: useVersionList.map((v) => v.brand).join(","),
      version: useVersionList.map((v) => v.version).join(","),
    };

    return extracted;
  }
}
