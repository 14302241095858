export interface ConnectionInfo {
  downlink?: number;
  effectiveType?: string;
  rtt?: number;
  saveData?: boolean;
}

declare type ConnectionNavigator = {
  connection?: ConnectionInfo;
  mozConnection?: ConnectionInfo;
  webkitConnection?: ConnectionInfo;
};

export class ConnectionSensor {
  public static getConnectionInfo(): ConnectionInfo | undefined {
    const connectionNav = navigator as ConnectionNavigator;

    const connectionData =
      connectionNav.connection ||
      connectionNav.mozConnection ||
      connectionNav.webkitConnection;

    return connectionData;
  }
}
