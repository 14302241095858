import { BaseEvent } from "./base-event";

export enum KeyPressEventType {
  UNKNOWN = "UNKNOWN",
  KEY_UP = "KEY_UP",
  KEY_DOWN = "KEY_DOWN",
}

/**
 * Represents a user interacting with a keyboard
 */
export interface KeyPressEvent extends BaseEvent {
  type: KeyPressEventType;
}
