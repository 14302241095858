import { BaseEvent } from "./base-event";

/**
 * The type of modification made to the form element.
 */
export enum InputChangeEventAction {
  UNKNOWN = "UNKNOWN",
  CUT = "CUT",
  PASTE = "PASTE",
  AUTOFILL = "AUTOFILL", // Not used currently
  REPLACEMENT = "REPLACEMENT", // Not used currently
}

/**
 * Represents a user modifying a form field on a webpage.
 */
export interface InputChangeEvent extends BaseEvent {
  /**
   * The type of modification made to the form element.
   */
  action: InputChangeEventAction;
  /**
   * Indicates whether or not the form element
   * had focus when the value was modified.
   */
  focus?: boolean | null;
}
