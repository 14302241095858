import { Battery, BatteryState } from "device/device-ingestion-request";
import { getLogger } from "logging/logger";

export class BatterySensor {
  public static async getBatteryInfo(): Promise<Battery> {
    const info = await (navigator as NavigatorBattery).getBattery();

    getLogger().debug("battery fetched", info);

    let status = BatteryState.UNKNOWN;

    if (info?.level >= 1) {
      status = BatteryState.FULL;
    } else if (info?.charging) {
      status = BatteryState.CHARGING;
    } else if (info !== undefined && !info.charging) {
      status = BatteryState.UNPLUGGED;
    }

    return {
      batteryLevel: info?.level,
      batteryState: status,
    };
  }
}

// Missing Types
export interface BatteryManager {
  charging: boolean;
  level: number;
}

export declare interface NavigatorBattery extends Navigator {
  getBattery(): Promise<BatteryManager>;
}
